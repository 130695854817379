import styles from './Portfolio.module.css';
import siteOne from '../../assets/site-1.png';
import siteTwo from '../../assets/site-2.png';
import siteThree from '../../assets/site-3.png';

function Portfolio() {

  return (
    <section id="projects" className={styles.container}>
      <h1 className="sectionTitle">Проекты</h1>
      <div className={styles.projectsContainer}>
        <a href="https://fastfoodlove.ru/" target="_blank" rel="noopener noreferrer" className={styles.project}>
          <div className={styles.imageContainer}>
            <img src={siteOne} alt="Сервис доставки еды" />
          </div>
          <h3 className={styles.projectTitle}>Сервис доставки еды</h3>
        </a>
        <a href="https://macik.beauty/" target="_blank" rel="noopener noreferrer" className={styles.project}>
          <div className={styles.imageContainer}>
            <img src={siteTwo} alt="Сайт салона красоты" />
          </div>
          <h3 className={styles.projectTitle}>Сайт салона красоты</h3>
        </a>
        <a href="https://wedding-inv.ru/" target="_blank" rel="noopener noreferrer" className={styles.project}>
          <div className={styles.imageContainer}>
            <img src={siteThree} alt="Сайт приглашение" />
          </div>
          <h3 className={styles.projectTitle}>Сайт приглашение</h3>
        </a>
      </div>
    </section>
  );
}


export default Portfolio;
