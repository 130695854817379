import './App.css';
import Hero from './sections/Hero/Hero';
import Portfolio from './sections/Portfolio/Portfolio';
import Skills from './sections/Skills/Skills';
import Experience from './sections/Experience/Experience';

function App() {
  return (
    <>
      <Hero />
      <Skills />
      <Portfolio />
    </>
  );
}

export default App;
